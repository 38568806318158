@tailwind base;

@tailwind components;

@tailwind utilities;

@layer base {
    :root {
        --primary: #d23581;
        --secondary: #F8C214;
        --xdark:  #011224;
        --dark:  #272c30;
        --ddbg:  #444b52;
        --light:  #909497;
        --lighter:  #dfdfdf;
        --silver:  #f4f4f4;
        --amber:  #e6a242;
        --warning:  #ffb144;
        --danger:  #d04040;
        --error:  #fb4d4d;
        --success:  #48c568;
        --palegreen:  #6ef390;
        --highlight:  #ffffb8;
        --blue:  #3fa2dc;
        --paleblue: #4dbbfb;
    }

    * {outline: 2px solid transparent !important; outline-offset: 2px !important;}
    body.no-scroll {overflow:hidden;}

    html,body {height: 100%; font-family: 'Source Sans Pro', sans-serif; font-size: 12px;}

    [multiple],
    [type=date],
    [type=datetime-local],
    [type=email],
    [type=month],
    [type=number],
    [type=password],
    [type=search],
    [type=tel],
    [type=text],
    [type=time],
    [type=url],
    [type=week],
    select,
    textarea { line-height: 16px; padding: 4px 8px; border-radius: 4px;
        @apply block w-full font-medium;
    }
    select {
        @apply rounded-br-none
    }
    select option {
        @apply bg-ddbg text-white border-b border-light py-1
    }
    [multiple]::placeholder,
    [type=date]::placeholder,
    [type=datetime-local]::placeholder,
    [type=email]::placeholder,
    [type=month]::placeholder,
    [type=number]::placeholder,
    [type=password]::placeholder,
    [type=search]::placeholder,
    [type=tel]::placeholder,
    [type=text]::placeholder,
    [type=time]::placeholder,
    [type=url]::placeholder,
    [type=week]::placeholder,
    select::placeholder,
    textarea::placeholder {
        @apply text-light italic font-normal text-sm;
    }
    .input-error [multiple],
    .input-error [type=date],
    .input-error [type=datetime-local],
    .input-error [type=email],
    .input-error [type=month],
    .input-error [type=number],
    .input-error [type=password],
    .input-error [type=search],
    .input-error [type=tel],
    .input-error [type=text],
    .input-error [type=time],
    .input-error [type=url],
    .input-error [type=week],
    .input-error select,
    .input-error textarea {
        @apply border-red-400 text-red-500 ring ring-red-200 ring-opacity-100;
    }
    .input-error .input-label {
        @apply text-red-500;
    }
    .custom-checkbox {
        @apply relative;
    }
    .custom-checkbox input[type="checkbox"] {
        @apply absolute left-0 right-0 opacity-0;
    }
    .custom-checkbox label {
        @apply block pl-9
    }
    .custom-checkbox span:before {content: ''; border-radius: 4px;
        @apply w-6 h-6 border border-light bg-silver inline-block absolute left-0 top-0;
    }
    .custom-checkbox span:after {content: ''; transform: rotate(40deg); border-radius: 2px;
        @apply w-1.5 h-3 border-r border-b border-dark inline-block absolute left-2 top-1 hidden;
    }
    .custom-checkbox input[type="checkbox"]:checked ~ span:before {
        @apply border-dark
    }
    .custom-checkbox input[type="checkbox"]:checked ~ span:after {
        @apply block
    }
}
body {
    @apply text-light;
}
.btn { cursor: pointer; line-height: 16px; padding: 4px 8px; border-radius: 4px;
    @apply text-sm font-semibold inline-block w-24 text-center
}
.btn--wide {
    @apply md:w-40 w-full;
}
.btn--dark {
    @apply bg-dark text-white border-dark;
}
.btn--sm {
    @apply py-1;
}
.btn--md {
    @apply px-5;
}
.btn--white {
    @apply border-white
}
.btn--border { line-height: 18px; padding-top: 2px; padding-bottom: 2px;
    @apply border bg-transparent
}
.btn--dark.btn--border {
    @apply text-dark
}
.error {}
.icon {width: 20px; height: 20px;
    @apply align-top inline-block;
}
.icon-svg {fill: currentColor; max-width: 100%; max-height: 100%;}
.icon--xl {
    @apply w-12 h-12;
}
.icon--lg {
    @apply w-10 h-10;
}
.icon--md {width: 20px; height: 20px;}
.icon--medium {width: 24px; height: 24px;}
.icon--sm {width: 20px; height: 20px;}
.icon--small {width: 14px; height: 14px;}
.icon--xs {width: 13px; height: 13px;}
.icon--ten {width: 10px; height: 10px;}
.icon--xssmall {width: 8px; height: 8px;}
.icon--xxs {
    @apply w-3 h-3;
}
.dropdown {
    @apply bg-ddbg text-white font-medium absolute right-0 top-full z-10 text-sm border border-dark;
}
.dropdown li a,
.dropdown li > div { line-height: 15px; padding: 4px 8px;
    @apply block whitespace-nowrap border-t border-light;
}
.dropdown li:first-child a,
.dropdown li:first-child > div {
    @apply border-transparent;
}
.dropdown li a:hover,
.dropdown li > div:hover {
    @apply bg-dark;
}
.owner-stamp {border-radius: 4px;
    @apply bg-silver rounded-br-none pl-1.5
}
.owner-stamp input {
    @apply bg-transparent;
}
.date-drop {
    @apply absolute right-0 top-full z-20
}

.priority {
    @apply rounded-md rounded-br-none bg-silver py-1 px-3 transition-all font-medium;
}
.priority--rounded {
    @apply rounded-md;
}
.priority--default {
    @apply  text-lighter
}
.priority--critical {
    @apply bg-danger text-white
}
.priority--high {
    @apply bg-error text-white
}
.priority--medium {
    @apply bg-warning text-white
}
.priority--low {
    @apply bg-success text-white
}

.alert-box {
    @apply p-4 mb-4 text-sm text-blue flex items-center bg-paleblue rounded-lg mt-0 mb-2
}
.alert-box--error {
    @apply bg-error text-white
}
.alert-box--warning {
    @apply bg-warning text-dark
}
.alert-box--success {
    @apply bg-success text-white
}

.alert-box .alert-box-icon {
    @apply mr-2;
}

.mini-timeline-wrapper {
    @apply relative py-1.5 px-0
}
.mini-timeline {
    @apply h-2 rounded relative z-0;
}
.mini-timeline .mt-completed {
    @apply absolute top-0 right-0 h-2 z-0 rounded-r;
}
.mini-timeline .mt-split { border: 0 solid transparent; border-left-width: 5px; border-bottom: 6px solid #fff; position: absolute; top: 0;
    @apply w-0 h-0 z-10;
}
.mini-timeline .mt-bar {
    @apply absolute top-0 h-2 bg-white w-1 z-20;
}
.mini-timeline .mt-circle {
    @apply absolute top-0 h-2 bg-white w-2 z-20 rounded;
}

.sticky__buttons {
    @apply bg-ddbg text-white rounded-tl border-b-4 border-xdark fixed bottom-0 right-0 z-40 flex
}
.sticky__buttons .sticky_btn {
    @apply px-4 py-3 text-center
}
.sticky__buttons .sticky_btn:first-child {
    @apply pl-5
}
.sticky__buttons .sticky_btn:last-child {
    @apply pr-5
}

@media print {
    .noprint {
        display: none !important;
    }
    .auto-complete .multiselect .multiselect-clear {
        display: none !important;
    }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-up-enter-active,
.fade-up-leave-active {
  transition: 0.5s ease;
}
.fade-up-enter-from,
.fade-up-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.container-sm {width: 990px; max-width: 100%;}
.no-arrows input[type="number"] {
    -moz-appearance: textfield;
}
.no-arrows input[type=number]::-webkit-inner-spin-button,
.no-arrows input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.nb-focus {
    /*@apply focus:ring-2 focus:ring-blue*/
}
.context__navs .auto-complete .multiselect.is-open {
    @apply rounded-bl-none;
}
.auto-complete .multiselect-options li {height:24px !important; padding:4px 8px !important;}
